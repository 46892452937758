body {
  margin: 0;
  overflow: hidden;
}

.options {
  width: 350px;
  height: 100%;
  background-color: #ffffff80;
  padding-left: 15px;
  transition: right .5s ease-in;
  position: absolute;
  right: 0;
}

.input-container {
  border: 1px solid #000;
  border-radius: 5px;
  flex-direction: column;
  margin: 5px;
  padding: 15px;
  display: flex;
}

.input-container > .section-title {
  color: #fff;
  padding-bottom: 15px;
  font-family: sans-serif;
  font-size: 18px;
}

#close-btn {
  color: purple;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid purple;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;
}

.close-tray {
  right: -100%;
}

.open-btn {
  color: #fca400b3;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #fca400b3;
  border-radius: 4px;
  margin: 10px;
  padding: 10px 15px;
  font-size: 24px;
  position: absolute;
  right: 0;
}

.hide-open-btn {
  visibility: hidden;
}

/*# sourceMappingURL=index.7dd5f5be.css.map */
