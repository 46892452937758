body {
    margin: 0;
    overflow: hidden;
}

.options {
    position: absolute;
    right: 0;
    width: 350px;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    transition: right 0.5s ease-in;
    padding-left: 15px;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    
    & > .section-title {
        padding-bottom: 15px;
        font-family: sans-serif;
        font-size: 18px;
        color: white;
    }
}

#close-btn {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: transparent;
    border: 2px solid purple;
    border-radius: 5px;
    color: purple;
    cursor: pointer;
}

.close-tray {
    right: -100%;
    // transition: right 0.5s ease-out;
}

.open-btn {
    position: absolute;
    margin: 10px;
    padding: 10px 15px;
    right: 0;

    background-color: transparent;
    border: 2px solid rgb(252, 164, 0, 0.7);
    border-radius: 4px;

    font-size: 24px;
    color: rgb(252, 164, 0, 0.7);
    cursor: pointer;

}

.hide-open-btn {
    visibility: hidden;
}